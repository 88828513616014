<template>
  <div>
    <div class="row">
      <div class="col-sm pb-3">
        <h3 class="card-label">
          {{ $t('payment_details') }}
          <span v-if="data.invoice_code">
            - {{ data.invoice_code }}
          </span>
        </h3>
      </div>
      <div class="col-auto pb-3">
        <h5 v-if="data.invoice_date" class="text-green">
          {{ data.invoice_date}}
        </h5>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body">
        <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
          <h6 class="my-auto text-white">{{ $t('the_payments')}}</h6>
        </div>
        <table class="table table-row-bordered text-center">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('amount')}}</th>
            <th>{{$t('treasury_checking.currency_name')}}</th>
            <th>{{ $t('created_at')}}</th>
          </tr>

          </thead>
          <tbody>
          <template v-if="paymentDetails && paymentDetails.length > 0">
            <tr v-for="(row, index) in paymentDetails" :key="index">
              <td>{{ row.payment_id}}</td>
              <td>{{ row.amount }}</td>
              <td>{{ row.currency_name }}</td>
              <td>{{ row.payment_date}}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="4">{{ $t('invoice_status.no_data') }}</td>
          </tr>
          </tbody>
        </table>
        <div class="mt-5 pt-4">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('banks.balance')}}</h6>
          </div>
          <table class="table table-row-bordered text-center">
            <thead>
            <tr>
              <th>{{ $t('amount')}}</th>
              <th>{{$t('treasury_checking.currency_name')}}</th>
              <th>{{ $t('created_at')}}</th>
            </tr>

            </thead>
            <tbody>
            <template v-if="balancePaymentDetails && balancePaymentDetails.length > 0">
              <tr v-for="(row, index) in balancePaymentDetails" :key="index">
                <td>{{ row.amount }}</td>
                <td>{{ row.currency_name }}</td>
                <td>{{ row.created_at}}</td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4">{{ $t('invoice_status.no_data') }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-5 pt-4">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('bonds')}}</h6>
          </div>
          <table class="table table-row-bordered text-center">
            <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('amount')}}</th>
              <th>{{$t('treasury_checking.currency_name')}}</th>
              <th>{{ $t('created_at')}}</th>
            </tr>

            </thead>
            <tbody>
            <template v-if="paymentIncomeInvoice && paymentIncomeInvoice.length > 0">
              <tr v-for="(row, index) in paymentIncomeInvoice" :key="index">
                <td>{{ row.expense_income_transaction}}</td>
                <td>{{ row.amount }}</td>
                <td>{{ row.currency_name }}</td>
                <td>{{ row.created_at}}</td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4">{{ $t('invoice_status.no_data') }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "view-payment-details-for-purchase-refund",
  data() {
    return {
      mainRoute: 'purchases/purchases-refund',
      data: {
        payment_details: [],
        balance_payment_details: [],
        payment_income_invoice: [],
      }
    };
  },
  computed: {
    paymentDetails() {
      return this.data.payment_details;
    },
    balancePaymentDetails() {
      // الرصيد
      return this.data.balance_payment_details;
    },
    paymentIncomeInvoice() {
      //   السندات
      return this.data.payment_income_invoice;
    }
  },
  methods: {
    getData() {
      ApiService.get(this.mainRoute + '/payment_details/' + this.$route.params.id)
          .then(({data}) => {
            this.data = data.data;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_refunds"),
              route: '/purchases/purchases-refunds'},
              {title: this.$t('payment_details')}, {title: this.data.purchase_refund_code}]);

          });
    }
  },
  mounted() {
    this.getData();
  }
}
</script>


<style scoped>

</style>
